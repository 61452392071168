import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import Quasar from 'quasar/src/vue-plugin.js';;
import quasarUserOptions from "./quasar-user-options";
import stores from "./stores";

import "vue3-lottie/dist/style.css";

console.log(process.env.VUA_APP_MODE);

createApp(App)
  .use(stores)
  .use(Quasar, quasarUserOptions)
  .use(router)
  .mount("#app");
