import type { RouteRecordRaw } from "vue-router";
import DefaultLayout from "../layouts/DefaultLayout.vue";
import MainLayout from "../layouts/MainLayout.vue";

const routes: RouteRecordRaw[] = [
  {
    path: "",
    component: MainLayout,
    meta: {
      requiresAuth: false,
    },
    children: [
      {
        path: "/:catchAll(.*)",
        redirect: "404",
      },
      {
        path: "404",
        name: "404",
        component: () =>
          import(/* webpackChunkName: "404" */ "../views/404View.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "",
        name: "Main",
        component: () =>
          import(/* webpackChunkName: "main" */ "../views/MainView.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "profile",
        name: "Profile",
        component: () =>
          import(/* webpackChunkName: "main" */ "../views/ProfileView.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "products",
        name: "ListProducts",
        component: () =>
          import(
            /* webpackChunkName: "product" */ "../views/product/ListProductsView.vue"
          ),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/product/view/:id",
        name: "DetailProducts",
        component: () =>
          import(
            /* webpackChunkName: "product" */ "../views/product/DetailProductsView.vue"
          ),
        props: true,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "product/create",
        name: "CreateProducts",
        component: () =>
          import(
            /* webpackChunkName: "product" */ "../views/product/CreateProductsView.vue"
          ),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "product/:id/edit",
        name: "EditProducts",
        component: () =>
          import(
            /* webpackChunkName: "product" */ "../views/product/EditProductsView.vue"
          ),
        props: true,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "providers",
        name: "ListProviders",
        component: () =>
          import(
            /* webpackChunkName: "provider" */ "../views/provider/ListProvidersView.vue"
          ),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "categories",
        name: "ListCategories",
        component: () =>
          import(
            /* webpackChunkName: "category" */ "../views/category/ListCategoriesView.vue"
          ),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "packaging",
        name: "ListPackaging",
        component: () =>
          import(
            /* webpackChunkName: "packaging" */ "../views/packaging/ListPackagingView.vue"
          ),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "settings/users",
        name: "ListUsers",
        component: () =>
          import(
            /* webpackChunkName: "settings" */ "../views/user/ListUsersView.vue"
          ),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "settings/users/create",
        name: "CreateUsers",
        component: () =>
          import(
            /* webpackChunkName: "settings" */ "../views/user/CreateUserView.vue"
          ),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "settings/users/:id/edit",
        name: "EditUsers",
        component: () =>
          import(
            /* webpackChunkName: "settings" */ "../views/user/EditUserView.vue"
          ),
        props: true,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "offers",
        name: "ListOffers",
        component: () =>
          import(
            /* webpackChunkName: "offer" */ "../views/offer/ListOffersView.vue"
          ),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "about",
        name: "About",
        component: () =>
          import(/* webpackChunkName: "main" */ "../views/AboutView.vue"),
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
  {
    path: "",
    component: DefaultLayout,
    children: [
      {
        path: "signin",
        name: "SignIn",
        component: () =>
          import(/* webpackChunkName: "auth" */ "../views/auth/SignInView.vue"),
        meta: {
          requiresAuth: false,
        },
      },
      {
        path: "forgot-password",
        name: "ForgotPassword",
        component: () =>
          import(
            /* webpackChunkName: "auth" */ "../views/auth/ForgotPasswordView.vue"
          ),
        meta: {
          requiresAuth: false,
        },
      },
      {
        path: "recover-password",
        name: "RecoverPassword",
        component: () =>
          import(
            /* webpackChunkName: "auth" */ "../views/auth/RecoverPasswordView.vue"
          ),
        meta: {
          requiresAuth: false,
        },
      },
      //   {
      //     path: "signup",
      //     name: "SignUp",
      //     component: () =>
      //       import(/* webpackChunkName: "auth" */ "../views/auth/SignUp.vue"),
      //     meta: {
      //       requiresAuth: false,
      //     },
      //   },
    ],
  },
];

export default routes;
