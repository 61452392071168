import { useAccountStore } from "@/stores/modules/account";
import { useRouter } from "vue-router";
import { storeToRefs } from "pinia";
import useQuasar from 'quasar/src/composables/use-quasar.js';;

export const useAccountsComposable = () => {
  const router = useRouter();
  const { setProfile } = useAccountStore();
  const { profile, menus } = storeToRefs(useAccountStore());
  const $q = useQuasar();
  const logOut = () => {
    $q.dialog({
      title: "Sair",
      message: "Deseja sair do sistema?",
      class: "q-pa-md",
      ok: {
        label: "Sim",
        noCaps: true,
        unelevated: true,
        padding: "sm lg",
      },
      cancel: {
        label: "Cancelar",
        noCaps: true,
        flat: true,
        padding: "sm lg",
      },
      persistent: true,
    }).onOk(() => {
      setProfile(null);
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
      router.push({ name: "SignIn" });
    });
  };
  const permissionAccess = (permission: string): boolean => {
    const permissions = profile.value.permissions;
    if (!permissions) return false;
    return permissions.includes(permission);
  };
  return {
    logOut,
    profile,
    menus,
    permissionAccess,
  };
};
