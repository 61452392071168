import axios, {
  AxiosError,
  type AxiosInstance,
  type AxiosResponse,
} from "axios";

import router from "../router";
import { refreshTokenService } from "../services/accountServices";

const http: AxiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE || "http://localhost:5000/api/v1/",
});

http.interceptors.request.use(
  (config: any) => {
    const token: string | null = localStorage.getItem("access_token");
    const refreshToken: string | null = localStorage.getItem("refresh_token");
    const isAuthPath: boolean = config.url.includes("auth/login") || false;
    const isRefreshPath: boolean =
      config.url.includes("auth/refresh-token") || false;
    if (token && !isAuthPath && !isRefreshPath)
      config.headers.Authorization = `Bearer ${token}`;
    else if (refreshToken && !isAuthPath && isRefreshPath)
      config.headers.Authorization = `Bearer ${refreshToken}`;
    return config;
  },
  (error: AxiosError) => Promise.reject(error)
);
http.interceptors.response.use(
  (response: AxiosResponse) => response,
  async (error: AxiosError) => {
    const originalConfig: any = error.config;
    if (originalConfig?.url !== "signin" && error.response) {
      if (error.response?.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        try {
          const { data, status } = await refreshTokenService();
          if (status === 200 || status === 201) {
            localStorage.setItem("access_token", data.access_token);
            localStorage.setItem("refresh_token", data.refresh_token);
            return http(originalConfig);
          }
        } catch (erro) {
          localStorage.removeItem("access_token");
          localStorage.removeItem("refresh_token");
          router.push("signin");
          return Promise.reject(erro);
        }
      }
    }
  }
);
export default http;
