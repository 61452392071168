import { useAccountStore } from "../stores/modules/account";
import { storeToRefs } from "pinia";
import Loading from 'quasar/src/plugins/Loading.js';;
import { getProfileService, getMenuService } from "../services/accountServices";
export default async (to: any) => {
  const token = localStorage.getItem("access_token");
  const { setProfile, setMenus } = useAccountStore();
  const { profile } = storeToRefs(useAccountStore());
  const getProfile = async (): Promise<any> => {
    try {
      Loading.show();
      const { status, data } = await getProfileService();
      if (status === 200) return data;
    } catch (error) {
      return false;
    } finally {
      Loading.hide();
    }
  };
  const getMenus = async (): Promise<any> => {
    try {
      Loading.show();
      const { status, data } = await getMenuService();
      if (status === 200) return data;
    } catch (error) {
      return false;
    } finally {
      Loading.hide();
    }
  };
  if (await to.matched.some((record: any) => record.meta.requiresAuth)) {
    if (!token) return "signin";
    if (!profile.value) {
      const [profile, menus] = await Promise.all([getProfile(), getMenus()]);
      if (profile) setProfile(profile);
      if (menus) setMenus(menus);
      else {
        localStorage.removeItem("access_token");
        localStorage.removeItem("refresh_token");
        return "signin";
      }
    }
    return true;
  } else {
    if (token) return "";
    else return true;
  }
};
