import script from "./MainLayout.vue?vue&type=script&lang=ts&setup=true"
export * from "./MainLayout.vue?vue&type=script&lang=ts&setup=true"

const __exports__ = script;

export default __exports__
import QLayout from 'quasar/src/components/layout/QLayout.js';
import QHeader from 'quasar/src/components/header/QHeader.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QToolbarTitle from 'quasar/src/components/toolbar/QToolbarTitle.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QDrawer from 'quasar/src/components/drawer/QDrawer.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QPageContainer from 'quasar/src/components/page/QPageContainer.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QLayout,QHeader,QToolbar,QBtn,QToolbarTitle,QImg,QSpace,QDrawer,QItem,QItemSection,QPageContainer});
