import type { AxiosInstance } from "axios";
import http from "../plugins/http";

export const loginService = async (
  email: string,
  password: string
): Promise<any> => {
  return await http.post<AxiosInstance>("auth/login", {
    email,
    password,
  });
};
export const refreshTokenService = async (): Promise<any> => {
  return await http.patch<AxiosInstance>("auth/refresh-token");
};
export const getProfileService = async (): Promise<any> => {
  return await http.get<AxiosInstance>("profile");
};

export const forgoutPasswordService = async (email: string): Promise<any> => {
  return await http.get<AxiosInstance>(`forgout-password/${email}`);
};

export const recoverPasswordService = async (
  token: string,
  password: string
): Promise<any> => {
  return await http.patch<AxiosInstance>(`recover-password/${token}`, {
    password,
  });
};

export const updatePasswordService = async (
  new_password: string
): Promise<any> => {
  return await http.patch<AxiosInstance>("update-password", { new_password });
};

export const getMenuService = async (): Promise<any> => {
  return await http.get<AxiosInstance>("menus");
};
export const getRolesService = async (): Promise<any> => {
  return await http.get<AxiosInstance>("roles");
};
